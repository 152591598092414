// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import React from 'react';
import logo from './logo.svg';
import receiptImage from './assets/img/receipt.png'
import './App.css';
import { Page, Grid, Image, Display, Spacer, User, useToasts } from '@zeit-ui/react'
import QRCode from 'react-qr-code'
import * as firebase from 'firebase'
import { ZeitProvider, CssBaseline, Button, Card } from '@zeit-ui/react'
import { Camera } from '@zeit-ui/react-icons'

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import { fireEvent } from '@testing-library/react';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyC_qteqlk_acu5pvcPq_GsuS-aXTqQiIKw",
  authDomain: "bondigital-432a5.firebaseapp.com",
  databaseURL: "https://bondigital-432a5.firebaseio.com",
  projectId: "bondigital",
  storageBucket: "bondigital.appspot.com",
  messagingSenderId: "141887562250",
  appId: "1:141887562250:web:744a47b08cc1b1967235f3",
  measurementId: "G-MHEVS41BRW"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

var db = firebase.firestore();
const currencies = { "ZAR": "R", "EUR": "€", "GBP": "£", "SEK": "kr", "USD": "$" }

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      receiptString: "error",
      generated: "",
      receipt: {}
    }
  }


  generateReceipt() {
    var samplePurchases = [
      {
        "quantity": "2",
        "productUuid": "39cde734-e893-11e6-b8f1-2d3db21fba6a",
        "variantUuid": "302b4d5c-e893-11e6-b8f1-2d3db21fba6a",
        "type": "PRODUCT",
        "id": "0",
        "vatPercentage": 25,
        "unitPrice": 2000,
        "rowTaxableAmount": 2240,
        "name": "Foo",
        "variantName": "",
        "discount": {
          "quantity": 1,
          "amount": 500
        },
        "discountValue": 500,
        "autoGenerated": false,
        "libraryProduct": true
      },
      {
        "quantity": "10",
        "productUuid": "4385c5ee-e893-11e6-b8f1-2d3db21fba6a",
        "variantUuid": "3bdf6232-e893-11e6-b8f1-2d3db21fba6a",
        "type": "PRODUCT",
        "id": "1",
        "vatPercentage": 12,
        "unitPrice": 1000,
        "rowTaxableAmount": 6429,
        "name": "Bar",
        "variantName": "",
        "discount": {
          "quantity": 1,
          "percentage": 10
        },
        "discountValue": 1000,
        "autoGenerated": false,
        "libraryProduct": true
      }, {
        "quantity": "1",
        "unitPrice": 7500,
        "rowTaxableAmount": 7500,
        "name": "Vetelevain",
        "variantName": "Vetelevain",
        "autoGenerated": false,
        "type": "CUSTOM_AMOUNT",
        "id": "Vetelevain_7500",
        "libraryProduct": false
      },
      {
        "quantity": "1",
        "unitPrice": 6000,
        "name": "Källarfranska",
        "variantName": "Källarfranska",
        "autoGenerated": false,
        "type": "CUSTOM_AMOUNT",
        "id": "Källarfranska_6000",
        "libraryProduct": false
      },
      {
        "quantity": "1",
        "unitPrice": 3500,
        "rowTaxableAmount": 3500,
        "name": "Baguette",
        "variantName": "Baguette",
        "autoGenerated": false,
        "type": "CUSTOM_AMOUNT",
        "id": "Baguette_3500",
        "libraryProduct": false
      }, {
        "quantity": "1",
        "vatPercentage": 12,
        "unitPrice": 1500,
        "rowTaxableAmount": 1339,
        "name": "Enkel espresso",
        "productUuid": "32986b58-0753-11e8-bd3c-f5639f19b561",
        "variantUuid": "32986db0-0753-11e8-bd3c-f5639f19b561",
        "type": "PRODUCT",
        "id": "32986db0-0753-11e8-bd3c-f5639f19b567_28000001",
        "autoGenerated": false,
        "libraryProduct": true
      }]
    var sampleCurrencies = ["EUR", "GBP", "ZAR", "USD"]
    var samplePaymentMethods = ["CASH", "VISA", "MS", "PAYPAL"]
    var selectedProducts = Array()
    var totalAmount = 0
    var vatAmount = 19
    var numberOfProducts = Math.floor(Math.random() * samplePurchases.length);
    for (var i = 0; i < numberOfProducts; i++) {
      let random = Math.floor(Math.random() * samplePurchases.length)
      totalAmount = totalAmount + samplePurchases[random].unitPrice
      selectedProducts.push(samplePurchases[random])
    }

    var orgU = ['1b84dbd0-fb65-11e7-9c34-d96d4f33e8fc', 'r', 'bm']

    try {
      const receipt = {
        organizationUuid: orgU[(Math.floor(Math.random() * orgU.length))],
        currency: sampleCurrencies[1],
        amount: totalAmount,
        vatAmount: vatAmount,
        timestamp: Date().toString(),
        // gpsCoordinates: {
        //     longitude: 0,
        //     latitude: 0,
        //     accuracyMeters: 1,
        // },
        purchaseNumber: (Math.floor(Math.random() * 200)),
        products: selectedProducts,
        paymentMethod: samplePaymentMethods[Math.floor(Math.random() * samplePaymentMethods.length)]
      }
      var that = this
      db.collection("receipts").add(receipt)
        .then(() => {
          that.setState({
            receiptString: receipt.id
          })
        })
      console.log("new receipt saved" + receipt.id)
    } catch (error) {
      console.log(error)
    }
    this.loadFirebase()
  }



  componentWillMount() {
    this.loadFirebase()
  }

  componentDidMount() {
    this.loadFirebase()
  }

  loadFirebase() {
    const that = this;
    db.collection("receipts")
      .orderBy("timestamp", "desc")
      .onSnapshot(function (querySnapshot) {
        console.log(querySnapshot)
        var docArray = querySnapshot.docs
        var receipt = docArray[docArray.length - 1]
        that.setState({
          receiptString: receipt.id
        })
        console.log(receipt.data())
      });
  }






  render() {
    return (
      <ZeitProvider>
        <CssBaseline />
        <Page size="large">
          <Page.Content>
            <Grid.Container gap={2} justify="center">
              <Grid xs={12}>
                <h4 style={{ lineHeight: 0.9 }}><strong>BON DIGITAL</strong><span style={{ fontFamily: 'IBM Plex Mono' }}> TOOLKIT</span></h4>
                <h1 style={{ lineHeight: 0.9 }}>Sample Receipt Generator</h1>
                <h3 style={{ marginTop: "50px" }}>How it Works</h3>
                <p>
                  A new receipt will be created using a combination of random values for currency, products and store.
                  </p>
                <p>
                  <Grid.Container gap={2} justify="right">
                    <Grid xs={24}>
                      <Button type="secondary" onClick={() => this.generateReceipt()}>Generate New Receipt</Button>
                    </Grid>
                  </Grid.Container>
                </p>
              </Grid>
              <Grid xs={12} style={{ textAlign: "center" }} >
                <h4 style={{ textDecoration: "underline" }}>Instructions</h4>
                <Grid.Container justify="center" gap={2}>
                  <Display shadow>
                    <QRCode value={"https://app.bondigital.io/public/" + this.state.receiptString} fgColor="white" bgColor="black" />
                  </Display>
                  <Grid>
                    <Camera></Camera>
                  </Grid>
                  <Grid style={{ textAlign: "left" }}>
                    Open the <strong>camera app</strong>
                    <br></br>
                    Scan the <strong>QR code</strong>
                  </Grid>
                </Grid.Container>
                {/* <Display shadow>
                  <Card>
                    <Grid.Container justify="center" style={{ textAlign: "left" }}>
                      <Grid xs={24}>
                        <h2 style={{ lineHeight: 0.3 }}><strong>Store Name</strong></h2>
                        <p invert>Date Created </p>

                      </Grid>
                      <Grid xs={20}>
                        <Spacer y={1}></Spacer>
                        {/* {this.state.receipt.products.map((value, index) => {
                          return (
                            <div key={index}>
                              <Grid.Container justify="left" gap={2} style={{ fontFamily: "IBM Plex Mono" }}>
                                <Grid xs={4} style={{ textAlign: "right" }}>
                                  {value.quantity}x
                            </Grid>
                                <Grid xs={12} style={{ textAlign: "left" }}>
                                  {value.name}
                                </Grid>
                                <Grid xs={6} style={{ textAlign: "right" }}>
                                  {currencies[this.state.receipt.currency]}    {value.unitPrice / 1000}
                                </Grid>
                              </Grid.Container></div>
                          )
                        })} 
                      </Grid>
                      <Grid xs={24}>

                      </Grid>
                    </Grid.Container>
                  </Card>
                </Display> */}

              </Grid>
            </Grid.Container>
          </Page.Content>
          <Page.Footer style={{ textAlign: "center" }}>
            <h5> BON DIGITAL</h5>
          </Page.Footer>
        </Page>
      </ZeitProvider>
    );
  }
}

export default App;
